import { Checkbox, Form, Input } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import FilterService from '../../../services/filter';
import { SelectItem } from '../../../components/form';
import { removeEmptyObject } from '../../../utils/removeEmptyObject';
import { debounce } from '../../../utils/debounce';

export const SearchFilters = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [subcategory, setSubcategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const valuesFromUrl = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;

  const initialValues = {
    search: valuesFromUrl?.search || '',
    category: valuesFromUrl?.category ? valuesFromUrl?.category?.split(',').map(Number) : [],
    subcategory: valuesFromUrl?.subcategory ? valuesFromUrl?.subcategory?.split(',').map(Number) : [],
  };

  const getCategories = useCallback(() => {
    FilterService.getNewOptions('category').then((res: any) => {
      const categoriesArray = res?.data?.map((item) => ({ label: item['name_val'], value: item['id'] }));
      setCategories(categoriesArray);
    });
  }, []);

  const getSubCategories = useCallback(() => {
    FilterService.getNewOptions('subcategory').then((res: any) => {
      const subCategoriesArray = res?.data?.map((item) => ({ label: item['name_val'], value: item['id'] }));
      setSubcategory(subCategoriesArray);
    });
  }, []);

  const onSearch = (values: any) => {
    if (!values.is_active) {
      values = {
        ...values,
        is_active: undefined,
      };
    }

    let normalized = {} as any;
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        if (Array.isArray(values[key])) {
          normalized[key] = values[key].join(',');
        } else {
          normalized[key] = values[key];
        }
      }
    });
    navigate({
      pathname: location.pathname,
      search: `page=1&limit=10&${qs.stringify(removeEmptyObject(normalized))}`,
    });
  };

  const debouncedOnSearch = debounce(onSearch, 1000);

  useEffect(() => {
    getSubCategories();
    getCategories();

    return () => {
      setSubcategory([]);
      setCategories([]);
    };
  }, [getSubCategories, getCategories]);

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
      // onFinish={onSearch}
      onValuesChange={(_, allValues) => debouncedOnSearch(allValues)}
      initialValues={initialValues || null}
    >
      <Form.Item name="search" label="Brand">
        <Input />
      </Form.Item>

      {/* <Form.Item name="category" label="Category" style={{ marginLeft: '16px', width: 150 }}>
        <SelectItem
          name="category"
          options={categories}
          disabledMode
          placeholder="Choose a category"
          // loading={loading}
          maxTagCount="responsive"
          // onDropdownVisibleChange={handleDropdownVisibleChange}
          // onDeselect={handleDeselectSubcategoryValue}
          allowClear={true}
          // onChange={(value) => console.log(value)}
        />
      </Form.Item> */}

      {/* <Form.Item name="subcategory" label="Subcategory" style={{ marginLeft: '16px', width: 150 }}>
        <SelectItem
          name="subcategory"
          options={subcategory}
          disabledMode
          placeholder="Choose a subcategory"
          // loading={loading}
          maxTagCount="responsive"
          // onDropdownVisibleChange={handleDropdownVisibleChange}
          // onDeselect={handleDeselectSubcategoryValue}
          allowClear={true}
        />
      </Form.Item> */}
    </Form>
  );
};
